.modal-content {
    width: 400px;
    margin: auto;
    top: 100px !important;
    left: auto!important;
}

.supplier_modal {
    width: 700px!important;
}

.modal-footer {
    padding: 19px 20px 20px;
    margin-top: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

#modal-header {
    background: #fff;
    color: #000;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    border-bottom: none;
    min-height: 16.428571429px;
    padding: 15px;
}

.product_center {
    text-align: center !important;
}

.modal-title {
    font-size: 17px;
    font-weight: 900;
    text-transform: capitalize;
    text-align: -webkit-center;
}

.button_price {
    display: flex;
}

.view {
    margin-top: 2rem !important;
    width: 129px;
}

.bk_category {
    text-align: end;
}

.add_category {
    background: green !important;
}

.button_price .send {
    background: green;
    padding-left: 1rem;
    margin-left: 1rem;
    width: 101px;
}

.close-btn {
    background-color: #bec3c7;
    border-color: #bec3c7;
    color: #fff;
}

#order-change-status {
    background-color: #56BA46;
    border-color: #56BA46;
    color: #fff
}

.label-danger {
    background-color: #FF6C60;
    color: #fff;
    padding: 0.5em 0.8em;
}

.label-default {
    background-color: #a1a1a1;
    color: #fff;
    padding: 0.5em 0.8em;
}

.label-success {
    background-color: #A9D86E;
}

.label-success {
    background-color: #A9D86E;
}

.label {
    padding: 0.5em 0.8em;
}

.label-success {
    background-color: #5cb85c;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

#modal-header_delete {
    text-align: center;
    /* padding: 2rem; */
    padding-top: 2rem;
    margin-bottom: -1rem;
}

#modal-header_delete img {
    height: 5rem;
}

.btn-toggle.btn-sm.active {
    transition: background-color 0.25s;
}

.btn-toggle.btn-secondary {
    color: #6b7381;
    background: #bdc1c8;
}

.btn-toggle.btn-sm {
    margin: 0 0.5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
}

.btn-toggle.btn-sm.btn-sm.active:before {
    opacity: 0;
}

.btn-toggle.btn-sm.btn-sm:before {
    text-align: right;
}

.btn-toggle.btn-sm.btn-sm:before, .btn-toggle.btn-sm.btn-sm:after {
    line-height: -0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
}

.btn-toggle.btn-sm:before {
    content: 'Off';
    left: -0.5rem;
}

.btn-toggle.btn-sm:before, .btn-toggle.btn-sm:after {
    line-height: 1.5rem;
    width: 0.5rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.55rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.btn-toggle.btn-sm.btn-sm.active:after {
    opacity: 1;
}

.btn-toggle.btn-sm.btn-sm:after {
    text-align: left;
    opacity: 0;
}

.btn-toggle.btn-sm.btn-sm:before, .btn-toggle.btn-sm.btn-sm:after {
    line-height: -0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
}

.btn-toggle.btn-sm:after {
    content: 'On';
    right: -0.5rem;
    opacity: 0.5;
}

.btn-toggle.btn-sm:after {
    line-height: 1.5rem;
    width: 0.5rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.55rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.btn-toggle.btn-sm.active>.handle {
    left: 1.6875rem;
    transition: left 0.25s;
}

.btn-toggle.btn-sm>.handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
}